import React from 'react';
import { Button } from 'react-bootstrap';
import { amountFormatter } from "../../Global/formatter";
import { useNavigate } from 'react-router-dom';


const Symbols = ({ symbol, filter }) => {

  const navigate = useNavigate();

  const ApplyHandler = () => {
    navigate('/transactionpage', { state: { symbol } });
  }


  return (
    <div className='card shadow-lg border-0 my-2'>
      <div className='row p-3 pb-1'>
        <div className='col-12'>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <h6 className='exchange-button'>{symbol.symbol}</h6>
            {symbol.exchange && <h6>{symbol.exchange}</h6>}
          </div>
        </div>
        <hr />
        <div className='col-6 pb-2 d-flex flex-column'>
          <span className='text-muted'>Start Date</span>
          <span className='text-muted'><b>{symbol.bidding_start_date}</b></span>
        </div>
        <div className='col-6 pb-2 d-flex flex-column'>
          <span className='text-muted'>End Date</span>
          <span className='text-muted'><b>{symbol.bidding_end_date}</b></span>
        </div>
        <div className='col-12 py-2 d-flex flex-column'>
          <span className='text-muted'>Price Range</span>
          <span className='text-muted'><b>₹ {symbol.min_price} - ₹ {symbol.max_price}</b></span>
        </div>
        <div className='col-6 py-2 d-flex flex-column'>
          <span className='text-muted'>Bid Lot</span>
          <span className='text-muted'><b>{symbol.lot_size}</b></span>
        </div>
        <div className='col-6 py-2 d-flex flex-column'>
          <span className='text-muted'>Total Value</span>
          <span><b>{amountFormatter.format(symbol.min_price * symbol.lot_size)}</b></span>
        </div>
        <Button variant="secondary" className='view-btn' size="sm" onClick={ApplyHandler} style={{ borderRadius: "0px" }}
          disabled={filter === "CLOSING"} // Disable button if filter is set to "Closing"
        >
          Click to Apply
        </Button>
      </div>

    </div>
  );
};

export default Symbols;
