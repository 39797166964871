import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
// import TimeLineComp from "../../TimeLineComponent";
import LoginForm from "../../LoginForm";

const IpoTab = ({ symbols }) => {
  const [datamodalShow, setDataModalShow] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  // const timelineItems = [
  //   {
  //     date: "March 20, 2024",
  //     title: "Event 1",
  //   },
  //   {
  //     date: "March 22, 2024",
  //     title: "Event 2",
  //   },
  //   {
  //     date: "March 20, 2024",
  //     title: "Event 1",
  //   },
  //   {
  //     date: "March 22, 2024",
  //     title: "Event 2",
  //   },
  //   {
  //     date: "March 20, 2024",
  //     title: "Event 1",
  //   },
  //   {
  //     date: "March 22, 2024",
  //     title: "Event 2",
  //   },
  //   // Add more timeline items as needed
  // ];

  if (symbols.length === 0) {
    return <h4 className="text-center pt-3">No Records</h4>;
  }

  return (
    <div>
      {symbols.map((symbol) => {
        return (
          <div
            key={symbol.symbolName}
            className="card p-3 mb-2 px-lg-2 shadow-lg border border-0"
          >
            <div className="row ipo-details align-items-center" >
              <div className="col-4 col-lg-2" onClick={() => {
                    setDataModalShow(true);
                    setSelectedSymbol(symbol);
                  }} style={{cursor:"pointer"}}>
                <div className="text-gray" >{symbol.exchange}</div>
                <div>{symbol.symbol}</div>
              </div>
              <div className="col-8 col-lg-3">
                <div className="text-gray">Open-close</div>
                <div>
                  {symbol.bidding_start_date} - {symbol.bidding_end_date}
                </div>
              </div>
              <div className="col-5 col-lg-3 py-2 py-lg-0">
                <div className="text-gray">Price Range</div>
                <div>
                  ₹ {symbol.min_price} - {symbol.max_price}
                </div>
              </div>
              <div className="col-7 col-lg-2 py-2 py-lg-0">
                <div className="text-gray">Amount</div>
                <div>
                  {symbol.min_price * symbol.min_bid_quantity}
                  <span className="fw-bold">
                    ({symbol.min_bid_quantity}Qty)
                  </span>
                </div>
              </div>
              <div className="col-5 col-lg-1">
                <button
                  className="d-none apply-lg-btn d-lg-block"
                  onClick={() => {
                    setDataModalShow(true);
                    setSelectedSymbol(symbol);
                  }}
                >
                  View
                </button>
              </div>
              <div className="col-5 d-none d-lg-block col-lg-1">
                <button
                  className="view-lg-btn"
                  onClick={() => {
                    setModalShow(true)
                  }}
                >
                  Apply
                </button>
                <LoginForm
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  fullscreen="md-down"
                  backdrop="static"
                />
              </div>
            </div>
            <Button variant="primary" className="w-100 view-btn d-lg-none mt-2" onClick={() => {
              setModalShow(true)
            }}>
              Apply
            </Button>
          </div>
        );
      })}
      {selectedSymbol && (
        <Modal
          size="md"
          show={datamodalShow}
          onHide={() => setDataModalShow(false)}
          backdrop="false"
          fullscreen="md-down"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modal-header">
            <img
              src="images/blue-shield.png"
              alt="symbol-img"
              className="img-fluid"
              width="50"
              height="auto"
            />
            <h3 className="wizard-title mt-1 mb-0 ms-2">
              {selectedSymbol.name}
            </h3>
          </Modal.Header>
          <Modal.Body className="symbol-modal">
            <div className="symbol-container p-3 py-2">
              <div className="row">
                <div className="symbol-data col-6 col-lg-4 py-2">
                  <div>Open-Close</div>
                  <div>
                    {selectedSymbol.bidding_start_date} - <br />{" "}
                    {selectedSymbol.bidding_end_date}
                  </div>
                </div>
                <div className="symbol-data col-6 col-lg-4 py-2">
                  <div>Isssue Price</div>
                  <div>
                    From ₹ {selectedSymbol.min_price} -{" "}
                    {selectedSymbol.max_price}
                  </div>
                </div>
                <div className="symbol-data col-6 col-lg-4 py-2">
                  <div>Lot Size</div>
                  <div>{selectedSymbol.min_bid_quantity} shares</div>
                </div>
                <div className="symbol-data col-6 col-lg-4 py-2">
                  <div>Issue Size</div>
                  <div>{selectedSymbol.issue_size} Shares</div>
                </div>
                <div className="symbol-data col-6 col-lg-4 py-2">
                  <div>Face Value</div>
                  <div>₹ {selectedSymbol.face_value}</div>
                </div>
                {/* <div className="symbol-data col-6 col-lg-4 py-2">
                        <div>Application Value</div>
                        <div>₹ 180000.00</div>
                      </div> */}
              </div>
            </div>
            {/* <TimeLineComp /> */}
            {/* <TimeLineComp items={timelineItems} />{" "} */}
            {/* Render the Timeline component with the data */}
          </Modal.Body>
          <Modal.Footer className="ymbol-modal">
            <Button
              variant="secondary bg-drk"
              onClick={() => setDataModalShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default IpoTab;
