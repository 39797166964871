import React from 'react'
import IpoHeader from '../Components/IpoHeader'
import OrderList from '../Components/PortalSections/Orders/OrdersList'

const OrdersPage = () => {
  return (
    <div>
      <IpoHeader isLogin={true} />
      <div className='container' style={{ paddingTop: "120px" }}>
        <OrderList />
      </div>
    </div>
  )
}

export default OrdersPage
