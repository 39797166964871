import React, { useState } from 'react';
import IpvHeader from '../Components/IpoHeader';
import { Tab, Tabs } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import LoginForm from '../Components/LoginForm';
import SubTabContainer from '../Components/SubTabContainer';
if (process.env.REACT_APP_COMPANY === "Nebsl") {
    import('../../Styles/ipo.css')
}
if (process.env.REACT_APP_COMPANY === "Bnr") {
    import('../../Styles/bnripo.css')
}

export default function UserAuthPage() {

    const [modalShow, setModalShow] = useState(false);
    const [activeTab, setActiveTab] = useState("OPENING");
    return (
        <>
            <div id="client">
                <IpvHeader isLogin={false} />
                <div className='primary-container'>
                    <div className="hero-5-bg position-relative" id="home">
                        <div className="d-flex flex-row align-items-center justify-content-between alert ipo-alert p-1 ps-4" role="alert" style={{ borderRadius: "0px" }}>
                            <div>
                                <h3>Invest in IPOs</h3>
                                <p>Gain early access to investment opportunities in pre-listed companies effortlessly through UPI. </p>
                            </div>
                            <img className='me-5 d-none d-md-block' src="/images/picture.svg" alt="illustration" width="130px" height="auto" />
                        </div>

                        <div className="home-container p-2 pt-0">
                            <div className='head-container'>
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='data-container'>
                                            <Tabs
                                                defaultActiveKey="OPENING"
                                                transition={true}
                                                id="fill-tab-example"
                                                className="tabs-container mb-3"
                                                fill
                                                onSelect={setActiveTab}
                                                activeKey={activeTab}
                                                unmountOnExit={true}
                                            >
                                                <Tab eventKey="OPENING" title="Current">
                                                    <SubTabContainer mainTab={activeTab} />
                                                </Tab>
                                                <Tab eventKey="UPCOMING" title="Upcoming">
                                                    <SubTabContainer mainTab={activeTab} />
                                                </Tab>
                                                <Tab eventKey="CLOSING" title="Closed">
                                                    <SubTabContainer mainTab={activeTab} />
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 d-flex justify-content-center'>
                                        <div className='card ipo-card shadow-lg w-75 text-center'>
                                            <div>
                                                <img className='img-fluid pt-4' src="images/ipo.jpg" alt="ipo" width="250" height="auto" />
                                            </div>
                                            <div className=' mt-2'>
                                                <p className='text-muted fs-5 mb-0'><b>Explore upcoming and <br />live IPOs</b></p>
                                                <p className='text-muted px-4 mt-2'>open a free demat account, and invest in any IPO hassle-free.</p>
                                                <Button variant="primary" className='w-100 apply-btn p-2 mt-2' onClick={() => setModalShow(true)}>
                                                    Apply an IPO
                                                </Button>
                                                <LoginForm
                                                    show={modalShow}
                                                    onHide={() => setModalShow(false)}
                                                    fullscreen="md-down"
                                                    backdrop="static"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ReKycFooter /> */}
            </div>
        </>

    )
}
