import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import axiosIpoInstance from '../../../../Global/IpoAxiosInit';
import { notyf } from '../../../../Global/Inits';
import { useNavigate } from 'react-router-dom';
import { getClientData } from '../../../../Global/IpoUtils';

const OrderList = () => {

    let sessiondata = getClientData();
    const history = useNavigate();

    const handlegoBack = () => {
        history('/homepage');
    };

    //Axios Data fetch constnts
    const [ordersData, setOrdersData] = useState([]);
    const [checkDataFetch, setCheckDataFetch] = useState(true);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(15);

    const fetchOrdersList = async page => {
        setCheckDataFetch(true);
        let data = JSON.stringify({
            ucc_id: sessiondata.personal_information.ucc_id,
            type: "make-transaction"
        })

        axiosIpoInstance.post(``, data)  //request with all initial filters
            .then(function (response) {
                if (response.data.status === "success") {
                    setOrdersData((response.data.data)); //passing rows data to variable
                    setCheckDataFetch(false); //user per loading preloader action on table
                    setTotalRows(response.data.total_users);
                } else {
                    notyf.error(response.data.message);
                }

            });
    };

    const handlePageChange = page => { //use to handle pagination page chage btns
        fetchOrdersList(page);
    };


    //if number of per page value changes than excute below request
    const handlePerRowsChange = async (newPerPage, page) => {
        setCheckDataFetch(true);
        axiosIpoInstance.get(`/get-user-transactions?ucc_id=${sessiondata.personal_information.ucc_id}`)
            .then(function (response) {
                if (response.data.status === "success") {
                    setOrdersData((response.data.data)); //passing rows data to variable
                    setPerPage(newPerPage);
                    setCheckDataFetch(false);
                } else {
                    notyf.error(response.data.message);
                }

            })
    };

    /* Custom style for react-table */
    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                borderColor: '#e7e9ed',
                color: '#5d6778',
                verticalAlign: 'middle',
                fontWeight: 'bold',
                fontSize: '13px',
                padding: '0.5rem 0.5rem'
            },
        },
        cells: {
            style: {
                padding: '0.5rem 0.5rem',
                verticalAlign: 'middle',
                color: '#5d6778',
                fontWeight: '500',
            },
        },
    };
    /* End of custom styles */

    const columns = React.useMemo( //coloums of the table
        () => [

            {
                name: 'Symbol',
                selector: row => row.symbol, // selector is the "key" in the data
                wrap: true
            },
            {
                name: 'PAN',
                selector: row => row.pan_no,
                wrap: true
            },
            {
                name: 'Application Number',
                selector: row => row.application_number,
                wrap: true
            },
            {
                name: 'Upi Id',
                selector: row => row.upi_id,
                wrap: true
            },
            {
                name: 'Payment Status',
                selector: row => row.payment_status,
                wrap: true
            },
            {
                name: 'Bid ID',
                selector: row => row.bids[0].bid_ref_no,
                wrap: true
            },
            {
                name: 'Quantity',
                selector: row => row.bids[0].quantity,
                wrap: true
            },
            {
                name: 'Price',
                selector: row => row.bids[0].price,
                wrap: true
            },
            {
                name: 'Amount',
                selector: row => row.bids[0].amount,
                wrap: true
            }


        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    return (
        <>
            <div className='heading'>
                <p>Orders Data</p>
            </div>
            <div className='card border-0 shadow-lg'>
                <DataTable
                    columns={columns}
                    data={ordersData}
                    progressPending={checkDataFetch}
                    highlightOnHover={true}
                    customStyles={customStyles}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationRowsPerPageOptions={[15, 30, 50, 100, 200]}
                    paginationPerPage={perPage}
                />
            </div>
            <div className='mt-3'>
                    <button className="btn btn-secondary" onClick={handlegoBack}> Back</button>
            </div>
        </>
    );
};

export default OrderList;
