import { Buffer } from 'buffer';
//utility function for get user data from Session storage
export const getClientData = () => {
    const clientStr = window.sessionStorage.getItem('userStr');
    if (clientStr) return JSON.parse(Buffer.from(clientStr, 'base64').toString('utf8'));
    else return null;
}

//utility function for get user Token from Session storage
export const getClientToken = () => {
    return window.sessionStorage.getItem('clentToken') || null;
}

//utility function for Set Client Session storage
export const setClientSession = (clentToken, userStr) => {
    window.sessionStorage.setItem('clentToken', clentToken);
    window.sessionStorage.setItem('userStr', Buffer.from(userStr, 'utf8').toString('base64'));
}

//utility function for remove Admin Session storage
export const removeClientSession = () => {
    window.sessionStorage.removeItem("clentToken");
}



