import React from "react";
import UserAuthPage from './Ipo/Pages/UserAuthPage';
import { Routes, Route } from 'react-router-dom';
import { PublicClientIpoRoute, PrivateClientIpoRoute } from './Global/IpoRouteLogics';
import Homepage from './Ipo/Pages/HomePage'
import TransactionPage from "./Ipo/Pages/TransactionPage";
import OrdersPage from "./Ipo/Pages/OrdersPage";

const RoutesPage = () => {
  return (
    <>
      <Routes>
          <Route path="/" element={
            <PublicClientIpoRoute>
              <UserAuthPage />
            </PublicClientIpoRoute>
          }
          />

          <Route path="/homepage" element={
            <PrivateClientIpoRoute>
              <Homepage />
            </PrivateClientIpoRoute>
          }
          />
          <Route path="/transactionpage" element={
            <PrivateClientIpoRoute>
              <TransactionPage />
            </PrivateClientIpoRoute>
          }
          />
           <Route path="/orderspage" element={
            <PrivateClientIpoRoute>
              <OrdersPage />
            </PrivateClientIpoRoute>
          }
          />
      </Routes>
    </>
  )
};
export default RoutesPage;