import React, { useEffect, useState } from "react";
import IpoHeader from "../Components/IpoHeader";
import Symbols from "../Components/Symbols";
import axiosIpoInstance from "../../Global/IpoAxiosInit";
import Select from "react-select";
import { notyf } from "../../Global/Inits";
import { Tab, Tabs, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const history = useNavigate();

  const handleGoToOrders = () => {
    history('/orderspage');
  };

  const [symbols, setSymbols] = useState([]);
  const [selFilter, setSelFilter] = useState("OPENING");
  const [selectedTab, setSelectedTab] = useState("ipo");
  const [selSubtype, setSelSubtype] = useState(() => {
    return selectedTab === "ipo" ? "ALL" : "NCB";
  });

  const filters = [
    { value: "OPENING", label: "Opening" },
    { value: "UPCOMING", label: "Upcoming" },
    { value: "CLOSING", label: "Closing" },
    { value: "ALL", label: "All" },
  ];

  const subtypeOptions = {
    ipo: [
      { value: "ALL", label: "All" },
      { value: "MAINBOARD", label: "General" },
      { value: "SME", label: "SME" },
      // { value: "CALL MONEY", label: "Call Money" },
      // { value: "SSE", label: "SSE" },
    ],
    bond: [
      { value: "NCB", label: "NCB" },
      { value: "SGB", label: "SGB" },
    ],
  };

  useEffect(() => {
    let url;
    let data = {};
    if (selectedTab === "ipo") {
      url = ``;
      data = JSON.stringify({
        sub_type: selSubtype,
        filter: selFilter,
        type: "ipo-masters"
      })
    } else if (selectedTab === "bond") {
      url = ``;
      data = JSON.stringify({
        bond_type: selSubtype,
        filter: selFilter,
        type: "ipo-bonds"
      })
    }

    axiosIpoInstance.post(url,data).then(function (response) {
      if (response.data.status === "success") {
        setSymbols(response.data.data);
      } else {
        notyf.error(response.data.message);
      }
    });
  }, [selFilter, selSubtype, selectedTab]);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    if (tab === "ipo") {
      setSelSubtype(
        subtypeOptions["ipo"].find((option) => option.value === "ALL").value
      );
    } else if (tab === "bond") {
      setSelSubtype(
        subtypeOptions["bond"].find((option) => option.value === "NCB").value
      );
    }
  };

  return (
    <div>
      <IpoHeader isLogin={true} />
      <div className="container hero-5-bg position-relative">
        <div className="d-flex flex-row align-items-center justify-content-between alert ipo-alert p-1 ps-4" role="alert">
          <div>
            <h3>Invest in IPOs</h3>
            <p>Gain early access to investment opportunities in pre-listed companies effortlessly through UPI. </p>
          </div>
          <img className='me-5 d-none d-md-block' src="/images/picture.svg" alt="illustration" width="130px" height="auto" />
        </div>
        <div className="card border-0 pt-2 p-lg-3">
          <div className="main-symbol-container p-2 pt-0">
            <div className="row align-items-center mb-3 custom-row"> {/* Added custom-row class */}
              <div className="col-auto">
                <Tabs
                  defaultActiveKey="ipo"
                  id="symbol-tabs"
                  onSelect={handleTabSelect}
                >
                  <Tab eventKey="ipo" title="IPO" />
                  {/* <Tab eventKey="bond" title="BOND" /> */}
                </Tabs>
              </div>
              <div className="col-auto">
                <Select
                className={"apx-form-select "}
                classNamePrefix="select"
                  value={subtypeOptions[selectedTab].find(
                    (option) => option.value === selSubtype
                  )}
                  onChange={(option) => setSelSubtype(option.value)}
                  options={subtypeOptions[selectedTab]}
                  placeholder="Select Subtype"
                />
              </div>
              <div className="col-auto">
                <Select
                  className={"apx-form-select "}
                  classNamePrefix="select"
                  value={filters.find((option) => option.value === selFilter)}
                  onChange={(option) => setSelFilter(option.value)}
                  options={filters}
                  placeholder="Data From"
                />
              </div>
              <div className="col-auto">
                <Button className="border-0" onClick={handleGoToOrders}>Orders</Button>
              </div>
            </div>
            <div className="row">
              {symbols.length === 0 ? (
                <div>No records to display</div>
              ) : (
                <div className="row">
                  {symbols.map((symbol) => (
                    <div
                      key={symbol.symbol}
                      className="col-12 col-md-4 col-lg-3"
                    >
                      <Symbols
                        symbol={symbol}
                        filter={selFilter}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
