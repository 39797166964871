const config = {
    VERSION: '2.0.0',
    COMPANY_NAME: "NORTHEASTLTD",
    COMPANY_SHORT_NAME: 'Northeastltd',
    COMPANY_FULL_NAME: 'Northeast Broking Services Limited',
    HEADER_NAME: 'nebsl',
    COPYRIGHT_YEAR: 2022,
    APP_LOGO: "/images/logo-blue.png",
    CONTACT_LINK: "https://www.northeastltd.com/contact-us/",
    HOME_LINK: "https://www.northeastltd.com/",
    ONBOARD_LINK: "https://onboard.northeastltd.com",
    APP_TITLE: "Northeastltd | Dashboard",
    DOMAIN: ".northeastltd.com",
    FAVICON: "images/fav.png",
    LOGO:"images/logo.png",
    PHONE: "040-66918888",
    EMAIL: "customercare@northeastltd.com",
    COMPANY_MINI_NAME:"NEBSL",
    NAME:"Northeast",
    ABOUT_LINK:"https://www.northeastltd.com/about-nbsl/",
    PRIVACY_POLICY_LINK:"https://www.northeastltd.com/privacy-policy/",
    APP_TITLE_CLIENT: "Northeastltd | A safe, online and seamless platform to track all your investments."
}

export default config;