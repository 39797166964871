import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import IpoTab from "./PortalSections/Tabs/IpoTab";
import axiosIpoInstance from "../../Global/IpoAxiosInit";
import { notyf } from "../../Global/Inits";

const IPOSubTabs = ({ onSelect, activeKey }) => {
    return (
        <Tab.Container
            defaultActiveKey={"MAINBOARD"}
            activeKey={activeKey}
            onSelect={onSelect}
            className="nav-bar p-0"
        >
            <div className="ipo-subitems d-flex align-items-center ms-auto mt-2 mt-lg-0">
                <Nav as="ul" className="d-flex align-items-center">
                    <Nav.Item>
                        <Nav.Link eventKey={"MAINBOARD"}>General</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={"SME"}>SME</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={"CALL MONEY"}>Call Money</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={"SSE"}>SSE</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </Tab.Container>
    );
};

const BondSubTabs = ({ onSelect, activeKey }) => {
    return (
        <Tab.Container
            defaultActiveKey={"NCB"}
            activeKey={activeKey}
            onSelect={onSelect}
            className="nav-bar p-0"
        >
            <div className="d-flex align-items-center ms-lg-auto mt-2 mt-lg-0">
                <Nav as="ul" className="d-flex align-items-center">
                    <Nav.Item>
                        <Nav.Link eventKey={"NCB"}>NCB</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={"SGB"}>SGB</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
        </Tab.Container>
    );
};

const SubTabContainer = ({mainTab}) => {
    const [topTab, setTopTab] = useState("ipo");
    const [subTab, setSubTab] = useState(() =>
        topTab === "ipo" ? "MAINBOARD" : "NCB"
    );

    const [symbols, setSymbols] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        let url;
        let data = {};
        if (topTab === "ipo") {
          url = ``;
          console.log(subTab);
          data = JSON.stringify({
            sub_type: subTab,
            filter: mainTab,
            type:"ipo-masters"
          })
        } else if (topTab === "bond") {
          url = ``;
          data = JSON.stringify({
            bond_type: subTab,
            filter: mainTab,
            type:"ipo-bonds"
          })
        }
    
        axiosIpoInstance.post(url,data).then(function (response) {
          if (response.data.status === "success") {
            setSymbols(response.data.data);
          } else {
            notyf.error(response.data.message);
          }
          setIsLoading(false)
        });
      }, [topTab, subTab, mainTab]);

    return (
        <div>
            <div className="client-tabs">
                <Tab.Container
                    defaultActiveKey="ipo"
                    unmountOnExit={true}
                    activeKey={topTab}
                    onSelect={(key) => {
                        setTopTab(key);
                        if (key === "bond") {
                            setSubTab("NCB");
                        } else {
                            setSubTab("MAINBOARD");
                        }
                    }}
                    className="nav-bar p-0"
                >
                    <Nav variant="pills" as="ul" className="d-flex align-items-center">
                        <Nav.Item as="li">
                            <Nav.Link eventKey="ipo">IPO</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="bond">BOND</Nav.Link>
                        </Nav.Item>
                        {topTab === "ipo" ? (
                            <IPOSubTabs activeKey={subTab} onSelect={setSubTab} />
                        ) : (
                            <BondSubTabs activeKey={subTab} onSelect={setSubTab} />
                        )}
                    </Nav>

                    <Tab.Content className="p-3">
                        <Tab.Pane eventKey="ipo">
                            {isLoading ? <p>Loading...</p> : <IpoTab symbols={symbols} />}
                        </Tab.Pane>
                        <Tab.Pane eventKey="bond">

                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};

export default SubTabContainer;
