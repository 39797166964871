import { useState, useEffect } from 'react';
import validator from 'validator';

//Email validation Hook
const useEmailVal = (email) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (validator.isEmpty(email)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Email is required'
      });

    } else if (!validator.isEmail(email)) {
      errorClass = "is-invalid";

      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Invalid Email'
      });

    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }

  }, [email]);

  return [data];
}
//Email validation Hook
const useEmailValidVal = (email) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (validator.isEmpty(email)) {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });

    }else{
    if (!validator.isEmail(email)) {
      errorClass = "is-invalid";

      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Invalid Email'
      });

    } else{
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }
  }


  }, [email]);

  return [data];
}

//Mobile validation Hook
const useMobileVal = (mobile) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (validator.isEmpty(mobile)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Mobile number is required'
      });

    } else if (!validator.isMobilePhone(mobile, 'en-IN')) {
      errorClass = "is-invalid";

      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Invalid mobile number'
      });

    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }

  }, [mobile]);

  return [data];
}


//Number validation Hook
const useNumberVal = (number) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (validator.isEmpty(number)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Input is required'
      });

    } else if (!validator.matches(number, /^[0-9]*$/)) {
      errorClass = "is-invalid";

      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Input is invalid'
      });

    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }

  }, [number]);

  return [data];
}

//Input Require validation Hook
const useReqVal = (input, txt) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (validator.isEmpty(input)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: txt+' is required'
      });

    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }

  }, [input, txt]);

  return [data];
}

//Input obj Require validation Hook
const useObjReqVal = (input, txt) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (Object.keys(input).length === 0) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: txt+' is required'
      });

    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }

  }, [input, txt]);

  return [data];
}


export { useEmailVal, useEmailValidVal, useMobileVal, useNumberVal, useReqVal, useObjReqVal };






















