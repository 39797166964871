import { Navigate } from "react-router-dom";
import { getClientToken } from "./IpoUtils";

function PublicClientIpoRoute({children}){
    return !getClientToken() ? <>{children}</> : <Navigate to= "/homepage"/>
  }
  
  function PrivateClientIpoRoute({children}){
    return getClientToken() ? <>{children}</> : <Navigate to= "/"/>
  }

export {PublicClientIpoRoute, PrivateClientIpoRoute};