import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { removeClientSession } from '../../Global/IpoUtils';
if(process.env.REACT_APP_COMPANY === "Nebsl") {
  import('../../Styles/ipo.css')
}
if(process.env.REACT_APP_COMPANY === "Bnr") {
  import('../../Styles/bnripo.css')
}

const IpoHeader = (props) => {
  const config = require(`../../CompanyConfigs/${process.env.REACT_APP_COMPANY}`).default

      //Logout handle
      const handleLogout = (e) => {
        e.preventDefault();
        removeClientSession();
        setTimeout(() => {
            window.location.href = '/';
        }, 500);
    }

    const handleOpenAccount = () => {
      window.location.href = `${config.ONBOARD_LINK}`;
    };

  return (
    <Navbar id="client" collapseOnSelect expand="lg" className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky shadow-sm">
      <Container>
        <Navbar.Brand className="logo mr-3" href={config.HOME_LINK} target='_blank'>
          <img src={config.LOGO} className="main-logo" alt="logo" width="auto" height="40px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto nav-links">
            <Nav.Link href={config.HOME_LINK}>Home</Nav.Link>
            <Nav.Link href={config.ABOUT_LINK}>About Us</Nav.Link>
            <Nav.Link href={config.CONTACT_LINK}>Contact Us</Nav.Link>
            {/* <Nav.Link href={config.IPO_LINK} target="_blank">Online IPO</Nav.Link> */}
          </Nav>
          {!props.isLogin && <Button className="px-lg-3 button-secondary ms-lg-2" onClick={handleOpenAccount} type="button">
              Open Account
            </Button>}
          {props.isLogin && <Button className="bg-danger px-lg-3 border-0 ms-lg-2" style={{fontSize:"14px"}} onClick={(e) => handleLogout(e)}>Logout!</Button>}

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default IpoHeader;
