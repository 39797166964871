import 'bootstrap/dist/css/bootstrap.min.css';
import 'notyf/notyf.min.css';
import RoutesPage from './Routes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <RoutesPage />
    </BrowserRouter>
  );
}

export default App;
