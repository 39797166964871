import { Notyf } from 'notyf';


//App Alert notifications init
const notyf = new Notyf({
    duration: 1000,
    position: {
      x: 'right',
      y: 'bottom',
    },
    types: [
        {
            type: 'success',
            background: '#004f03',
            duration: 4000,
            dismissible: true
          },
      {
        type: 'error',
        background: 'indianred',
        duration: 4000,
        dismissible: true
      }
    ]
  });

  export {notyf};