import axios from "axios";
import { getClientToken, removeClientSession } from "./IpoUtils";
import { notyf } from './Inits';


const baseURL = process.env.REACT_APP_BACKEND_URL;
const config = require(`../CompanyConfigs/${process.env.REACT_APP_COMPANY}`).default

let headers = { // Headers
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Entity': `${config.HEADER_NAME}`,
  
};

const axiosIpoInstance = axios.create({ //Creating instance
  baseURL: baseURL,
  headers,
  withCredentials: true,
});

axiosIpoInstance.interceptors.request.use(function (config) {
  const token = getClientToken();
  config.headers.apxtoken =  token;
  return config;
});

axiosIpoInstance.interceptors.response.use((response) => { //Created Response Interceptor
  return response;

}, (error) => {
  console.log('interseptor response error');
  if (error.response.status === 401) { //if auth token not valid on every request than [logout and redirect /]
    notyf.error("Session expired login again!");
    removeClientSession();
    setTimeout(() => {
     window.location.href = '/';
   }, 1800);
   
  } else {
    notyf.error(error.response.data.message);
  }
  return Promise.reject(error);

});

export default axiosIpoInstance;
