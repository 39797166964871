import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Animate } from 'react-simple-animate';
import { notyf } from '../../Global/Inits';
import { setClientSession } from '../../Global/IpoUtils';
import axiosIpoInstance from '../../Global/IpoAxiosInit';
import { useNumberVal } from '../../Hooks/FormValidations';

const LoginForm = (props) => {
    const [ucc, setUcc] = useState('');
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();
    const config = require(`../../CompanyConfigs/${process.env.REACT_APP_COMPANY}`).default


    /* Button Preloader disable vars on form submit */
    const [defloadText, setdefloadText] = useState("Send OTP"); //for change in button text
    const [defDisableState, setDefDisableState] = useState(""); // disable / enable

    const [verifyLoadText, setVerifyLoadText] = useState("Verify");
    // const [VerifyDisableState, setVerifyDisableState] = useState(""); // disable / enable

    const [otpVerifyVisable, setOtpVerifyVisable] = useState(false);

    /* validation constants */
    const [defOtpValidation, setOtpValidation] = useState({ errorClass: '', msg: '' }); //Deafult Validation vars
    const [otpValRes] = useNumberVal(otp);  // init validation hook

    /* OTP Resend Logic */
    const [resendOtpCount, setResendOtpCount] = useState(40);
    const [resendOtpbtn, setResendOtpbtn] = useState(false);
    useEffect(() => {
        if (resendOtpCount > 0) {
            const resendCount = setTimeout(() => setResendOtpCount(resendOtpCount - 1), 1000);
            return () => {
                clearTimeout(resendCount);
            }

        } else {
            setResendOtpbtn(true);
        }
    }, [resendOtpCount]);

    const onResendOtpClick = async (e) => { //OTP Resend request
        e.preventDefault();

        var data = JSON.stringify({
            "ucc_id": ucc,
            "type": "user-auth"
        });

        await axiosIpoInstance.post('', data)
            .then(function (response) {
                if (response.data.status === "success") {
                    notyf.success(response.data.message);
                    setResendOtpCount(40);
                    setResendOtpbtn(false);
                } else {
                    notyf.error(response.data.message);
                }

            });
    }
    /* ----- */

    //form submit Handler
    const sinupSubmit = async (e) => {
        e.preventDefault();
        // setMobValidation(mobValRes); //passing errors to display

        // if (mobValRes.status === true) {

        setdefloadText("Sending OTP ...");
        setDefDisableState('disabled');

        var data = JSON.stringify({
            "ucc_id": ucc,
            "type": "user-auth"
        });
        // Post Request
        // await rekycClientInstance.get('/csrf-cookie');
        await axiosIpoInstance.post('', data)
            .then(function (response) {

                if (response.data.status === "success") {
                    setDefDisableState('');
                    setdefloadText("OTP Sent");
                    notyf.success(response.data.message);
                    setOtpVerifyVisable(true);

                } else {
                    setDefDisableState('');
                    setdefloadText("Continue");
                    notyf.error(response.data.message);
                }
            })
            .catch(function (error) {
                setDefDisableState('');
                setdefloadText("Continue");
            });

        // }

    }//end submit handler

    //form submit Handler
    const verifySubmit = async (e) => {
        e.preventDefault();
        setOtpValidation(otpValRes); //passing errors to display

        if (otpValRes.status === true) {

            setVerifyLoadText("Verifying ...");
            // setVerifyDisableState('disabled');
            var data = JSON.stringify({
                "ucc_id": ucc,
                "input_otp": otp,
                "type": "user-otpverify"
            });

            // Post Request
            await axiosIpoInstance.post('', data)
                .then(function (response) {

                    if (response.data.status === "success") {
                        // setVerifyDisableState('');
                        setVerifyLoadText("Done");
                        let sessionData = response.data.data;
                        setClientSession(response.data.token, JSON.stringify(sessionData));
                        navigate('/homepage');
                        return

                    } else {
                        // setVerifyDisableState('');
                        setVerifyLoadText("Continue");
                        notyf.error(response.data.message);
                    }
                })
                .catch(function (error) {
                    // setVerifyDisableState('');
                    setVerifyLoadText("Continue");
                });

        }

    }

    const handleOpenAccount = () => {
        window.location.href = `${config.ONBOARD_LINK}`;
    };

    const handleClose = () => {
        props.onHide();
        setUcc('');
        setOtp('');
        setOtpVerifyVisable(false);
  
    };

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal'
                onHide={handleClose}
            >
                <Modal.Header closeButton className='login-modal'>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='login-modal'>
                    <div className='head-container p-5 pt-5 pt-lg-2'>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className='side-text-container'>
                                    <div className='d-flex flex-row'>
                                        <div class="divider"></div>
                                        <div className='side-text'>
                                            <h1 className='side-head-text'>Do not have<br /> an account<br /> yet ?</h1>
                                            <button class="button p-2 mt-3" onClick={handleOpenAccount} ><span>Open Account </span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0" >
                                <div className='login-container'>
                                    <div className='card text-center p-3  p-lg-5 shadow-lg' style={{ borderRadius: "20px" }}>
                                        <div className='auth-form'>
                                            <div className='otp-icon'><img src='images/blue-shield.png' alt="shield-img" width="100px" height="auto" /></div>
                                            {!otpVerifyVisable ? <>
                                                <div className='auth-text mt-3'>Enter UCC Code</div>
                                                <div className='form-items'>
                                                    <form>
                                                        <div className="form-row pb-3 pt-3">
                                                            <input type="text" className={"form-control "} value={ucc} onChange={(e) => setUcc(e.target.value.toUpperCase())} placeholder="Enter Ucc..." />
                                                        </div>
                                                        <Button type="submit" className='button-primary w-100' variant="secondary" onClick={(e) => sinupSubmit(e)} disabled={defDisableState}>
                                                            <Animate play start={{ opacity: 0.5 }} end={{ opacity: 1 }}><span>{defloadText}</span> </Animate>
                                                        </Button>
                                                    </form>
                                                </div>
                                            </>
                                                :
                                                <>
                                                    <div className='auth-text mt-3'>Enter OTP Code</div>
                                                    <div className='form-items'>
                                                        <form>
                                                            <div className="form-row pt-2">
                                                            <div className='text-end'>
                                                                    {resendOtpbtn ?
                                                                        (<span className="resendotp-btn ms-auto">
                                                                            <button type="button" onClick={(e) => onResendOtpClick(e)} className="btn-link">Resend OTP</button>
                                                                        </span>) :
                                                                        (<span className="resendotp-count">
                                                                            Resend in <span style={{ color: "#4184e4" }}>{resendOtpCount}</span>
                                                                        </span>)
                                                                    }
                                                                </div>
                                                                <div className="input-group flex-nowrap">
                                                                    <input type="text" className={"form-control mr-md-3 " + defOtpValidation.errorClass} name="apxMobileOtp" onChange={(e) => setOtp(e.target.value)} id="apxMobileOtp" maxLength="6" placeholder="Enter OTP..." />
                                                                </div>
                                                                
                                                            </div>
                                                            <p className="val-response-text">{defOtpValidation.msg}</p>
                                                            <Button type="submit" className='button-primary w-100' variant="secondary" onClick={(e) => verifySubmit(e)} disabled={defDisableState}>
                                                                <Animate play start={{ opacity: 0.5 }} end={{ opacity: 1 }}><span>{verifyLoadText}</span> </Animate>
                                                            </Button>
                                                            {/* <p className="small font-weight-semibold pt-1 mb-0">Change Trading Code? <a href="#!" onClick={props.apxEvent} className="link-custom">Change</a></p> */}

                                                        </form>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoginForm
