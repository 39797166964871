import React, { useState, useEffect } from 'react';
import IpoHeader from '../Components/IpoHeader';
import { useLocation } from 'react-router-dom';
import { amountFormatter } from '../../Global/formatter';
import { getClientData, getClientToken} from '../../Global/IpoUtils';
import { useObjReqVal, useReqVal } from '../../Hooks/FormValidations';
import { Animate } from 'react-simple-animate';
import { Button } from "react-bootstrap";
import { notyf } from '../../Global/Inits';
import axiosIpoInstance from '../../Global/IpoAxiosInit';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import AlertText from '../Components/AlertText';
import Select from 'react-select';
import { CheckCircleFill } from 'react-bootstrap-icons';


const TransactionPage = () => {
    let sessiondata = getClientData();
    const token = getClientToken()
    // const [upiId, setUPIid] = useState("");
    const [stepsTooltip, SetStepsTooltip] = useState(false);

    const [upiAddress, setUpiAddress] = useState('');
    const [upiBank, setUpiBank] = useState({ value: '' });
    const [upiBankOptions] = useState([
        { value: "@ybl", label: '@ybl' },
        { value: "@upi", label: '@upi' },
        { value: "@kbl", label: '@kbl' },
        { value: "@sbi", label: '@sbi' },
        { value: "@idbi", label: '@idbi' },
        { value: "@kotak", label: '@kotak' },
        { value: "@indus", label: '@indus' },
        { value: "@paytm", label: '@paytm' },
        { value: "@rbl", label: '@rbl' },
        { value: "@sib", label: '@sib' },
        { value: "@uboi", label: '@uboi' },
        { value: "@BARODAMPAY", label: '@BARODAMPAY'},
        { value: "@aubank", label: '@aubank'},
        { value: "@axisbank", label: '@axisbank'},
        { value: "@bandhan", label: '@bandhan'},
        { value: "@dlb", label: '@dlb'},
        { value: "@uco", label: '@uco'},
        { value: "@citi", label: '@citi'},
        { value: "@citigold", label: '@citigold'},
        { value: "@dbs", label: '@dbs'},
        { value: "@freecharge", label: '@freecharge'},
        { value: "@okhdfcbank", label: '@okhdfcbank'},
        { value: "@okaxis", label: '@okaxis'},
        { value: "@oksbi", label: '@oksbi'},
        { value: "@okicici", label: '@okicici'},
        { value: "@yesg", label: '@yesg'},
        { value: "@hsbc", label: '@hsbc'},
        { value: "@icici", label: '@icici'},
        { value: "@indianbank", label: '@indianbank'},
        { value: "@ikwik", label: '@ikwik'},
        { value: "@axl", label: '@axl'},
        { value: "@ibl", label: '@ibl'},
        { value: "@yespay", label: '@yespay'},
        { value: "@unionbankofindia", label: '@unionbankofindia'},
        { value: "@uboi", label: '@uboi'},
        { value: "@unionbank", label: '@unionbank'},
        { value: "@federal", label: '@federal'},
        { value: "@allbank", label: '@allbank'},


    ])

    const [defaultCutPrice, setDefaultCutPrice] = useState(false);
    const [upi, setUpi] = useState('');
    const [upiVerification, setUpiVerification] = useState(false);

    const history = useNavigate();

    const handlegoBack = () => {
        history('/homepage');
    };

    // const [upiReqValidation, setUPIreqValidation] = useState({ errorClass: '', msg: '' });
    // const [upiReqValRes] = useReqVal(upi, "UPI ID");

    const [upiaddReqValidation, setUpiAddReqValidation] = useState({ errorClass: '', msg: '' });
    const [upiAddReqValRes] = useReqVal(upiAddress, "UPI Address");

    const [upiBankReqValidation, setUpiBankReqValidation] = useState({ errorClass: '', msg: '' });
    const [upiBankReqValRes] = useObjReqVal(upiBank, "select Bank");

    useEffect(() => { //Change Validation to empty or default state on input change
        // setUPIreqValidation({ errorClass: '', msg: '' });
        setUpiAddReqValidation({ errorClass: '', msg: '' });
        setUpiBankReqValidation({ errorClass: '', msg: '' });

    }, [upiAddress, upiBank]);

    const location = useLocation();
    const symbolItem = location.state.symbol;
    const lotSize = symbolItem.lot_size;
    const [totalLotSize, setTotalLotSize] = useState(symbolItem.lot_size);
    const [cutOffPrice, setCutOffPrice] = useState(symbolItem.max_price)

    // Function to handle incrementing lot size
    const incrementLotSize = () => {
        setTotalLotSize(prevLotSize => prevLotSize + lotSize);
    };

    // Function to handle decrementing lot size
    const decrementLotSize = () => {
        if (totalLotSize > lotSize) { // Make sure the lot size doesn't go below the minimum value
            setTotalLotSize(prevLotSize => prevLotSize - lotSize);
        }
    };

    // const calculatedLotSize = (!defaultCutPrice) ? cutOffPrice * totalLotSize : symbolItem.max_price * totalLotSize;
    

    //upi post handler
    const proceedUpiHandler = () => {
        // setUPIreqValidation(upiReqValRes);
        // if (upiReqValRes.status) {

        let data = JSON.stringify({
            ucc_id: sessiondata.personal_information.ucc_id,
            mobile_no: sessiondata.personal_information.mobile_no,
            symbol: symbolItem.symbol,
            upiFlag: true,
            upi_id: upi,
            quantity: totalLotSize,
            price: symbolItem.max_price,
            amount: symbolItem.max_price * totalLotSize,
            type: "make-transaction"

        });

        axiosIpoInstance.post(``, {
            headers: {
                'Mobile': sessiondata.personal_information.mobile_no,
                'Access-Token': token
            }
        }, data)
            .then(function (response) {
                if (response.data.status === "success") {
                    notyf.success(response.data.message);
                    // history('/homepage');
                    // return
                    SetStepsTooltip(true);

                }
                else {
                    notyf.error(response.data.message);
                }
            });
        // }
    }



    const upiVerifyHandler = (e) => {
        e.preventDefault();

        // setUPIreqValidation(upiReqValRes);
        setUpiAddReqValidation(upiAddReqValRes)
        setUpiBankReqValidation(upiBankReqValRes);

        if (upiAddReqValRes.status && upiBankReqValRes.status) {

            const upiInfo = upiAddress + (upiBank ? upiBank.value : '');

            axiosIpoInstance.post(`/check-upi-validation/${upiInfo}`)
                .then(function (response) {
                    if (response.data.status === "success") {
                        setUpi(upiInfo);
                        setUpiVerification(true);
                        notyf.success(response.data.message);
                    }
                    else {
                        notyf.error(response.data.message);
                    }
                });
        }

    }

    return (
        <div>
            <IpoHeader isLogin={true} />
            <div className='container' style={{ paddingTop: "120px" }}>
                <div className='card transaction-card w-75 mx-auto shadow-lg border-0 p-3'>
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <div className='ipo-details-card p-1 p-lg-3'>
                                <h6 className='p-2 text-white text-center'>IPO Details</h6>
                                <div className='ipo-details px-2'>
                                    <div>
                                        <span className='fs-5' style={{ fontWeight: "600" }}>{symbolItem.symbol}</span><br />
                                        <span className='text-muted'>{symbolItem.name}</span>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='symbol-item'>
                                        <span>Issue Start</span>
                                        <span>{symbolItem.bidding_start_date}</span>
                                    </div>
                                    {/* <hr className='my-1'></hr> */}
                                    <div className='symbol-item'>
                                        <span>Issue End</span>
                                        <span>{symbolItem.bidding_end_date}</span>
                                    </div>
                                    {/* <hr className='my-1'></hr> */}
                                    <div className='symbol-item'>
                                        <span>Cutoff price</span>
                                        <span>{symbolItem.max_price}</span>
                                    </div>
                                    <div className='symbol-item'>
                                        <span>Bid Lot</span>
                                        <span>{symbolItem.lot_size}</span>
                                    </div>
                                    <div className='symbol-item'>
                                        <span>Total Value</span>
                                        <span>{amountFormatter.format(symbolItem.min_price * symbolItem.lot_size)}</span>
                                    </div>
                                    <div className='symbol-item'>
                                        <span>Issue Price Range</span>
                                        <span>₹{symbolItem.min_price} - ₹{symbolItem.max_price}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='ipo-details-card p-1 p-lg-3'>
                                <h6 className='p-2 text-white text-center'>Client Details</h6>
                                <div className='ipo-details px-2'>
                                    <div className='symbol-item'>
                                        <span>Client UCC</span>
                                        <span>{sessiondata.personal_information.ucc_id}</span>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='symbol-item'>
                                        <span>Client Name</span>
                                        <span>{sessiondata.personal_information.applicant_name}</span>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='symbol-item'>
                                        <span>Client Id</span>
                                        <span>{sessiondata.personal_information.client_id}</span>
                                    </div>
                                    <hr className='my-1'></hr>
                                    <div className='symbol-item'>
                                        <span>Dp id</span>
                                        <span>{sessiondata.personal_information.dp_id}</span>
                                    </div>
                                    <hr className='my-1'></hr>

                                    <div className='symbol-item'>
                                        <span>Pan Number</span>
                                        <span>{sessiondata.personal_information.pan_no}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='bank-card p-lg-3 pt-lg-5'>
                                <div className="alert alert-warning" role="alert">
                                    Please check your UPI 2.0 supported app for the mandate request and accept it!
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-lg-6'>
                                        <label className='form-label'>UPI Address</label>

                                        <div className="input-group flex-nowrap mb-3">
                                            {/* <span className="input-group-text" id="basic-addon1">+91</span> */}
                                            {/* <input type="text" className={"form-control mr-md-3 " + defMobValidation.errorClass} value={inputMobile} name="apxMobile" onChange={(e) => setInputMobile(e.target.value)} id="apxMobile" maxLength="10" placeholder="Enter Mobile No..." pattern="[0-9]+" /> */}
                                            <input type="text" className={"form-control mr-md-3 " + upiaddReqValidation.errorClass} value={upiAddress} name="upiaddress" onChange={(e) => setUpiAddress(e.target.value)} id="upiaddress" placeholder="Enter UPI Address" />

                                        </div>
                                        <p className="val-response-text text-danger">{upiaddReqValidation.msg}</p>

                                    </div>
                                    <div className="col-lg-4 pb-2">
                                        <label className='form-label'>Select UPI</label>

                                        <Select
                                            // value={upiBank}
                                            // defaultValue={{ value: '', label: 'All' }}
                                            className={"apx-form-select " + upiBankReqValidation.errorClass}
                                            classNamePrefix="select"
                                            onChange={setUpiBank}
                                            options={upiBankOptions}
                                            placeholder="Select"
                                            isSearchable={true}
                                        />
                                        <p className="val-response-text">{upiBankReqValidation.msg}</p>

                                    </div>


                                    <div className='col-lg-2 mt-2'>
                                        {/* <button className='btn button-primary my-4' onClick={(e) => upiVerifyHandler(e)}> verify</button> */}
                                
                                        <Button type="submit" className='btn-sm button-primary mt-4' variant="secondary" onClick={(e) => upiVerifyHandler(e)}>
                                            <Animate play start={{ opacity: 0.5 }} end={{ opacity: 1 }}><span>Verify</span> </Animate>
                                        </Button>
                                    </div>

                                    {upiVerification && (<div className='col-lg-12 mb-3'>
                                        {upi}<span className='ms-2 f-12' style={{ color: "green" }}>Verified<CheckCircleFill className='ps-1' /></span>
                                    </div>)}

                                    <div className='col-12 col-lg-6 pb-2'>
                                        <div className="form-row d-flex flex-row">
                                            <input className="form-check-input me-2 p-2" type="checkbox" onChange={() => setDefaultCutPrice(!defaultCutPrice)} checked={defaultCutPrice} id="nbsl-consent2" />
                                            <label className="form-check-label con" htmlFor="nbsl-consent2">
                                                Cutoff-price
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-12 col-lg-6 pb-2'>
                                    {/* <label className='form-label'>Price</label> */}

                                        <div className="form-row">
                                            <div className={"form-holder "}>
                                                <input type="number" className={"form-control "} value={cutOffPrice} 
                                                onChange={(e) => setCutOffPrice(e.target.value)} 
                                                min={symbolItem.min_price}  max={symbolItem.max_price} disabled={!defaultCutPrice}  onKeyDown={(e) => { if (e.keyCode === 8) e.preventDefault(); }}/>
                                            </div>
                                            {/* <p className="val-response-text">{upiReqValidation.msg}</p> */}
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-12 pb-3'>
                                        <div className="form-row">
                                            <div className={"form-holder "}>
                                                <label className="form-label fw-bold">Quantity</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary" type="button" onClick={decrementLotSize}>-</button>
                                                    </div>
                                                    <input type="number" className="form-control" value={totalLotSize} readOnly />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-secondary" type="button" onClick={incrementLotSize}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-12'>
                                        <div className="form-row">
                                            <div className={"form-holder "}>
                                                <label className="form-label fw-bold">Amount to be Blocked</label>
                                                <input type="text" className={"form-control "} value={amountFormatter.format(cutOffPrice * totalLotSize)} disabled />
                                            </div>
                                            {/* <p className="val-response-text">{upiReqValidation.msg}</p> */}
                                        </div>
                                    </div>

                                    <div className='col-12 col-lg-12 pt-lg-4'>
                                        <Button type="submit" className='button-primary w-100' variant="secondary" onClick={proceedUpiHandler}>
                                            <Animate play start={{ opacity: 0.5 }} end={{ opacity: 1 }}><span>Proceed</span> </Animate>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ms-auto mt-2'>
                        <button className="btn btn-secondary" onClick={handlegoBack}> Back</button>
                    </div>
                    <SweetAlert
                        show={stepsTooltip}
                        onConfirm={handlegoBack}
                        onCancel={() => SetStepsTooltip(false)}
                        confirmBtnText="Ok, I got it!"
                        focusCancelBtn
                        btnSize="sm">
                        <AlertText />
                    </SweetAlert>
                </div>
            </div>
        </div>
    )
}

export default TransactionPage
