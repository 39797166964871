import React from 'react'

const AlertText = () => {
    return (
        <div className='p-3 pb-0'>
            <img src="/images/success-img.png" className='img-fluid' alt="requestsent" width="250px" height="auto"/>
            <h5 className='text-success pb-2'>Authorization request sent successfully!</h5>
            <p className='fs-6 text-muted'>
                It typically requires approximately 2 minutes to process the mandate request. After receiving request, please approve the mandate on your UPI app to complete the process.</p>
            <p></p>
        </div>
    )
}

export default AlertText
